import {useParams} from "react-router-dom";
import {useQuery} from "react-query";
import LeagueAPI from "../../api/leagueApi";
import {Heading, Select, Spinner, Table, TableCaption, Tbody, Td, Th, Thead, Tr} from "@chakra-ui/react";
import {useState} from "react";

const RosterStatsEmbed = () => {
    const params = useParams()
    const rosterId = Number(params.rosterId)
    const isValidId = !isNaN(rosterId) && rosterId > 0

    const [chosenWeek, setChosenWeek] = useState(0)

    const rosterQuery = useQuery({
        queryKey: ["roster", rosterId],
        queryFn: () => LeagueAPI.getRoster(rosterId),
        enabled: isValidId,
        onSuccess: (data) => {
            setChosenWeek(data.league.currentIteration - 1)
        }
    })

    if (!isValidId) {
        return (
            <div>
                Error: Invalid ID
            </div>
        )
    }

    if (rosterQuery.isLoading) {
        return (
            <Spinner size={'md'} />
        )
    }

    if (!rosterQuery.isSuccess) {
        return (
            <div>
                Roster Not Found
            </div>
        )
    }

    let { league, weeklyStats} = rosterQuery.data

    const startingWeek = 3
    const weekOptionsArray = Array.from({length: league.currentIteration - startingWeek + 1}, (_, i) => i + startingWeek)
    const weekOptions = weekOptionsArray.map(week =>
        <option value={week} key={week}>Week {week}</option>
    )


    return (
        <div>
            <div style={{width: "160px"}}>
                <Select placeholder='Choose Week' value={chosenWeek}
                        onChange={event => setChosenWeek(Number(event.target.value))}>
                    {weekOptions}
                </Select>
            </div>
            <Table variant={'striped'} size={'sm'}>
                <TableCaption placement={"top"}>
                    <Heading size={'sm'}>
                        Week {chosenWeek} Stats
                    </Heading>
                </TableCaption>
                <Thead>
                    <Tr>
                        <Th>Player</Th>
                        <Th>KL</Th>
                        <Th>BL</Th>
                        <Th>AC</Th>
                        <Th>AS</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {weeklyStats
                        .filter((stat) => stat.iteration === chosenWeek)
                        .sort((a, b) => a.rosterMember.jerseyNumber - b.rosterMember.jerseyNumber)
                        .map(st => (
                        <Tr key={st.id}>
                            <Td>{st.rosterMember.jerseyName} ({st.rosterMember.jerseyNumber})</Td>
                            <Td>{st.kills}</Td>
                            <Td>{st.blocks}</Td>
                            <Td>{st.aces}</Td>
                            <Td>{st.assists}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </div>
    );
}

export default RosterStatsEmbed;
